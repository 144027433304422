export const ROUTES = {
    auth: {
        login: 'accounts/login',
        logout: 'accounts/logout',
        refresh_token: 'accounts/refresh',
    },
    userManagement: {
        experts: {
            list: '/experts',
            create: '/experts',
            updateUserInfo: '/experts/{id}/user-info',
            changePassword: '/experts/{id}/change-password',
            changePermissions: '/experts/{id}/permissions',
            retriveUserInfo: '/experts/{id}/user-info',
            retriveUserPermissions: '/experts/{id}/permissions'
        },
    },
    users: {
        list: '/users',
        create: '/users',
        update: '/users/{id}',
        change_password: '/users/{id}/password',
        retrive: '/users/{id}'
    },
    verifications: {
        kyc: {
            list: '/requests/verifications',
            requestSummary: '/requests/verifications/{id}/summary',
            personalInformationRequest: '/requests/verifications/{id}/personal-information',
            phoneNumberRequest: '/requests/verifications/{id}/phone-number',
        },
        bank: {
            requestSummary: '/bank/requests/verifications/{id}/summary',
            verificationInformation: '/bank/requests/verifications/{id}/account-information',
            list: '/bank/requests/verifications'
        }
    },
    requests: {
        deposits: {
            list: "/deposits",
            bankDetail: "/deposits/{id}/bank-detail",
            detail: "/deposits/{id}/detail",
            paymentHistory: "/deposits/{id}/payment-history",
            statusHistory: "/deposits/{id}/status-history",
            summary: "/deposits/{id}/summary"
        }
    },
    walletManagement: {
        list: '/wallets'
    },
    configuration: {
        banks: {
            list: '/banks',
            create: '/banks',
            update: '/banks',
            retrive: '/banks/{id}'
        },
        currency: {
            list: '/currencies',
            create: '/currencies',
            update: '/currencies/{id}',
            retrive: '/currencies/{id}'
        },
        KYCProviders: {
            list: '/providers/kyc',
            create: '/providers/kyc',
            updateBaseInformation: '/providers/kyc/{id}/base-information',
            updateSettings: '/providers/kyc/{id}/setting',
            updateAPISecurity: '/providers/kyc/{id}/security',
            retriveKYCDetails: '/providers/kyc/{id}',
            retriveAPISecurity: '/providers/kyc/{id}/security'
        },
        paymentMethods: {
            list: '/payment-methods',
            create: '/payment-methods',
            retrive: '/payment-methods/{id}',
            update: '/payment-methods/{id}'
        },
        paymentProviders: {
            list: '/providers/payment',
            create: '/providers/payment',
            updateBaseInformation: '/providers/payment/{id}/base-information',
            updateBusiness: '/providers/payment/{id}/business',
            updateAPISecurity: '/providers/payment/{id}/security',
            retriveBaseInformation: '/providers/payment/{id}/base-information',
            retriveBusiness: '/providers/payment/{id}/business',
            retriveAPISecurity: '/providers/payment/{id}/security'
        }
    },
    file: {
        upload: '/files/upload',
        download: '/files/upload',
        delete: '/files/upload'
    },
    exchangeRate: {
        list: '/exchange-rates',
        update: '/exchange-rates',
        report: '/exchange-rates/report'
    }
}