@let form = formGroup();
@let toFormControlName = toControlName();
@let fromFormControlName = fromControlName();
<form [formGroup]="form" class="flex">
    <mat-form-field class="flex-1 no-trailing">
        <mat-label>{{labelFrom()}}</mat-label>
        <button mat-icon-button type="button" matSuffix [owlDateTimeTrigger]="fromDateTimePicker">
            <span class="fa-regular fa-calendar-minus text-secondary-60 fa-2xs"></span>
        </button>
        <input 
            #fromDatePickerInput 
            matInput 
            [formControlName]="fromFormControlName" 
            [owlDateTime]="fromDateTimePicker" 
            (dateTimeChange)="updateToDateMin()"
            appDateTimeFormat
            appPreventInputTyping
            >
        <owl-date-time #fromDateTimePicker></owl-date-time>
    </mat-form-field>
    <mat-form-field class="flex-1 no-leading">
        <mat-label>{{labelTo()}}</mat-label>
        <button mat-icon-button type="button" matSuffix [owlDateTimeTrigger]="toDateTimePicker">
            <span class="fa-regular fa-calendar-minus text-secondary-60 fa-2xs"></span>
        </button>
        <input 
            matInput 
            [formControlName]="toFormControlName"
            [owlDateTime]="toDateTimePicker"
            [min]="minToDate"
            appDateTimeFormat
            appPreventInputTyping
        >
        <owl-date-time #toDateTimePicker></owl-date-time>
        <mat-error *ngIf="toDateTime?.errors?.['owlDateTimeMin']">
            The "To date" must be after the "From date".
        </mat-error>
    </mat-form-field>
</form>