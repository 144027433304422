import { Location } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CrudActionEnum } from '@core/enums';
import { AppSnackBarService, HttpService } from '@core/services';
import { Subscription } from 'rxjs';

@Component({
  standalone: true,
  imports: [],
  template: '',
})
export abstract class CrudComponent<T, FT = never> {
  action: CrudActionEnum;
  entity: T | undefined;
  form!: FormGroup;
  subscription!: Subscription;
  CrudActionEnum = CrudActionEnum;
  router = inject(Router);
  route = inject(ActivatedRoute);
  /**
   * Consumers must specify the form type generic value to initialize 
   * readonly fields for the edit mode of the consumer entity
   */
  readOnlyFormControlNames: (keyof FT)[] | undefined;
  hiddenFormControlNames: Set<keyof FT> = new Set<keyof FT>();
  /**
   * Inject dependencies required for the component.
   */
  readonly appSnackBarService = inject(AppSnackBarService);
  readonly httpService = inject(HttpService);
  readonly location = inject(Location);
  private readonly activatedRoute = inject(ActivatedRoute);

  /**
   * Initialize the component by determining the action (create or update)
   * based on the presence of the entity. Ensure that the route's data
   * contains the entity or is named correctly to resolve the action mode.
   */
  constructor() {
    this.entity = this.activatedRoute.snapshot.data['entity'] as T;
    this.action = this.entity ? CrudActionEnum.Update : CrudActionEnum.Create;
  }

  /**
   * Abstract methods for handling CRUD operations.
   * Implementations should define the specific behavior for creating and updating
   * based on the action type.
   */
  abstract update(): void;
  abstract create(): void;

  /**
   * Handle form submission by invoking the appropriate method
   * based on the current action (create or update).
   */
  submit() {
    if (this.action === CrudActionEnum.Create) {
      this.create();
    } else if (this.action === CrudActionEnum.Update) {
      this.update();
    }
  }

  /**
   * Get the form data cast to the generic type T.
   * This method returns the current value of the form as type T.
   */
  get payload(): T {
    return this.form.value as T;
  }
  get fc() {
    return this.form.controls
  }
  /**
 * Generates a relative URL array for navigating back to the entity list page.
 * The array contains the appropriate number of '..' based on the current URL structure,
 * allowing the application to determine how many levels to navigate back.
 *
 * For example:
 * - Given the URL '/cpl/configurations/payment-providers/1/edit/base-information',
 *   it will return ['../..'] to navigate back to '/cpl/configurations/payment-providers'.
 * - Given the URL '/cpl/configurations/payment-methods/1/edit/asd',
 *   it will also return ['../..'].
 * - Given the URL '/cpl/configurations/payment-providers/1/edit/asd/asd/asd',
 *   it will return ['../../..'] to navigate three levels back.
 *
 * @returns {string[]} An array representing the relative path to navigate back.
 */
  get dataTableUrl() {
    const urlSegments = this.router.url.split('/').filter(segment => segment !== '');
    const idIndex = urlSegments.findIndex(segment => !isNaN(Number(segment))); 
    let segmentsToGoBack; 
    if (idIndex !== -1) { 
      segmentsToGoBack = Math.max(1, urlSegments.length - idIndex);
    } else {
      segmentsToGoBack = 1;
    }
    return [new Array(segmentsToGoBack).fill('..').join('/')];
  }

  navigateBackToEntityListPage() {
    this.router.navigate(this.dataTableUrl, { relativeTo: this.route });
  }
}
