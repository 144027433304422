import { NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { Component, Input, input } from '@angular/core';
import { SortDirection } from '@angular/material/sort';

@Component({
  selector: 'app-mat-sort-indicator',
  standalone: true,
  imports: [
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault
  ],
  templateUrl: './mat-sort-indicator.component.html',
  styleUrl: './mat-sort-indicator.component.scss'
})
export class MatSortIndicatorComponent {
  direction = input<SortDirection>('');
  @Input("app-mat-sort-indicator") id!:string;
}
