import { ChangeDetectionStrategy, Component, contentChild, inject, input, TemplateRef, viewChild } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { SafeAny } from "@core/types";
import { DialogContentDirective, DialogFooterDirective, DialogTitleDirective } from "../directives";

@Component({
    selector: 'app-dialog',
    standalone: true,
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseDialog {
    width = input<string>('400px');
    data = input<SafeAny>({});
    closeIcon = input<string | TemplateRef<void>>('fa-solid fa-xmark');
    title = input<string | TemplateRef<void>>();
    closeable = input(true);

    dialogRef: MatDialogRef<SafeAny> | null = null;
    dialog = inject(MatDialog);
    dialogContent = contentChild(DialogContentDirective);
    dialogFooter = contentChild(DialogFooterDirective);
    dialogTitle = contentChild(DialogTitleDirective);

    dialogContainer = viewChild<TemplateRef<SafeAny>>('dialogContainer');

    openModal() {
        if (!this.dialogRef) {
            this.dialogRef = this.dialog.open(this.dialogContainer()!, {
                width: this.width(),
                data: this.data
            });

            this.dialogRef.afterClosed().subscribe(() => {
                this.dialogRef = null;
            });
        }
    }

    closeModal() {
        if (this.dialogRef) {
            this.dialogRef.close();
            this.dialogRef = null;
        }
    }
    get isStringCloseIcon() {
        return typeof this.closeIcon() === 'string';
    }
    get isStringTitle() {
        return typeof this.title() === 'string';
    }

    get closeIconTemplateRef() {
        return this.closeIcon() as TemplateRef<void>;
    }
    get titleTemplateRef() {
        return this.title() as TemplateRef<void>;
    }
}