@let dialogContenttemplateRef = dialogContent()?.templateRef ?? defaultContent;
@let dialogTitletemplateRef = dialogTitle()?.templateRef ?? defaultTitle;
@let dialogFootertemplateRef = dialogFooter()?.templateRef ?? defaultFooter;

<ng-template #dialogContainer>
    <div class="dialog">
        <h2 class="m-0 Subtitle-1 text-secondary-50 dialog__title">
            <ng-content *ngTemplateOutlet="dialogTitletemplateRef" />
            @if(closeable()){
            @if(isStringCloseIcon){
            <span [ngClass]="closeIcon()" (click)="closeModal()" class="dialog__title__close-icon"></span>
            }@else {
            <ng-content *ngTemplateOutlet="closeIconTemplateRef" (click)="closeModal()" />
            }
            }
        </h2>
        <mat-dialog-content>
            <ng-content *ngTemplateOutlet="dialogContenttemplateRef" />
        </mat-dialog-content>
        <mat-dialog-actions>
            <ng-content *ngTemplateOutlet="dialogFootertemplateRef" />
        </mat-dialog-actions>
    </div>
</ng-template>

<ng-template #defaultTitle>
    @if(title()){
    @if(isStringTitle){
    <span [ngClass]="title()"></span>
    }@else {
    <ng-content *ngTemplateOutlet="titleTemplateRef" />
    }
    }
</ng-template>
<ng-template #defaultContent>Default Content</ng-template>
<ng-template #defaultFooter>
    <button mat-button (click)="closeModal()">Close</button>
</ng-template>