import { NgIf } from '@angular/common';
import { Component, effect, inject, input, OnInit } from '@angular/core';
import { FormGroup, FormGroupName, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { MatErrorMessagesComponent } from '../mat-error-messages/mat-error-messages.component';
import { CommonValidators } from '@core/validators';
import { SafeAny } from '@core/types';
const { required } = CommonValidators;
@Component({
  selector: 'app-mat-range-input',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    NgIf,
    NgxMaskDirective,
    MatCheckbox,
    MatErrorMessagesComponent
  ],
  providers: [provideNgxMask()],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './mat-range-input.component.html',
  styleUrl: './mat-range-input.component.scss'
})
export class MatRangeInputComponent implements OnInit {

  checkboxLabel = input('Enable Range');
  textPrefix = input('USD');
  precision = input(0);
  minLabel = input('Min');
  maxLabel = input('Max');
  useEnabler = input(true);

  minFormControlName = input<string>('min');
  maxFormControlName = input<string>('max');
  checkboxFormControlName = input<string>('enable');
  minValue: string | null | undefined;
  maxValue: string | null | undefined;
  private formGroupName = inject(FormGroupName, { self: true });
  /**
   *
   */
  constructor() {
    effect(() => {
      const precision = this.precision();
      if (precision) {
        this.minControl.setValue(this.limitPrecision(this.minValue ?? this.minControl.value))
        this.maxControl.setValue(this.limitPrecision(this.maxValue ?? this.maxControl.value))
      }
    })
  }
  ngOnInit(): void {
    if (this.useEnabler())
      this.onEnabledChange();
  }
  onEnabledChange(): void {
    this.form.updateValueAndValidity();
    this.handleEnableChange();
  }
  onInput(field: 'minValue' | 'maxValue', event: SafeAny): void {
    const inputValue = event.target.value;
    if (field === 'maxValue')
      this.maxValue = inputValue;
    else
      this.minValue = inputValue;
  }

  private handleEnableChange(): void {
    const controls = [this.maxControl, this.minControl];
    if (this.isEnabled)
      controls.forEach(control => {
        control.enable({ emitEvent: false });
        control.setValidators([required]);
        control.updateValueAndValidity();
      })
    else controls.forEach(control => {
      control.disable({ emitEvent: false });
      control.removeValidators(required);
      control.updateValueAndValidity();
    });
  }
  private limitPrecision(value: string | null | undefined): number | null {
    // Check for null or undefined
    if (value == null) return null;

    // Check if the value is a valid number
    const numberValue = parseFloat(value.toString().replace(/,/g, ''));
    if (isNaN(numberValue)) return null; // Return null for non-numeric values

    const parts = value.toString().split('.');

    // Limit the decimal part based on precision
    if (parts[1] && parts[1].length > this.precision()) {
      return +`${parts[0]}.${parts[1].substring(0, this.precision())}`;
    }

    // Return the original value as a number
    return numberValue;
  }
  get form(): FormGroup {
    return this.formGroupName.control;
  }

  get isEnabled(): boolean {
    return this.useEnabler() ? this.form.get(this.checkboxFormControlName())?.value : false;
  }

  get minControl() {
    return this.form.get(this.minFormControlName())!;
  }

  get maxControl() {
    return this.form.get(this.maxFormControlName())!;
  }
}