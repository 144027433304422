@let prefix = textPrefix();
@let minRangeLabel = minLabel();
@let maxRangeLabel = maxLabel();
@let minFormControl = minFormControlName();
@let maxFormControl = maxFormControlName();
@let checkboxFormControl = checkboxFormControlName();
@let checkboxVisibility = useEnabler();

<div [formGroup]="form" class="grid grid-cols-2">
    @if(checkboxVisibility){
    <mat-checkbox [formControlName]="checkboxFormControl" (ngModelChange)="onEnabledChange()" color="primary"
        class="col-span-2 -ml-2.5">
        {{ checkboxLabel() }}
    </mat-checkbox>
    }

    <mat-form-field class="no-trailing col-span-1" [floatLabel]="isEnabled ? 'always':'auto'">
        <mat-label>{{minRangeLabel}}</mat-label>
        <input matInput [formControlName]="minFormControl" [mask]="'separator.' + precision()" thousandSeparator=","
            [allowNegativeNumbers]="false" (input)="onInput('minValue', $event)">
        <span matTextSuffix>{{prefix}}</span>
        <mat-error appMatErrorMessages></mat-error>
    </mat-form-field>

    <mat-form-field class="no-leading col-span-1" [floatLabel]="isEnabled ? 'always':'auto'">
        <mat-label>{{maxRangeLabel}}</mat-label>
        <input matInput [formControlName]="maxFormControl" [mask]="'separator.' + precision()" thousandSeparator=","
            [allowNegativeNumbers]="false" (input)="onInput('maxValue', $event)">
        <span matTextSuffix>{{prefix}}</span>
        <mat-error appMatErrorMessages></mat-error>
    </mat-form-field>
</div>