import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appDialogTitle]',
  standalone: true
})
export class DialogTitleDirective {

  constructor(public templateRef: TemplateRef<object>) { }

}
