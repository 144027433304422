import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { VALIDATION_ERROR_MESSAGES, REGEXES } from "@core/constants";
import { ValidationError } from "@core/interfaces";

export class CommonValidators extends Validators {
    static customPattern(pattern: string | RegExp, label: string): ValidatorFn {
        return (control: AbstractControl): Record<string, ValidationError> | null => {
            const invalid = Validators.pattern(pattern);
            const errors = invalid(control);
            if (errors) {
                return { pattern: VALIDATION_ERROR_MESSAGES['pattern']({ label }) };
            }
            return null;
        };
    }
    static graterThanOrEqual(minControlName = 'min', maxControlName = 'max'): ValidatorFn {
        return CommonValidators.createComparisonValidator(
            (max, min) => max >= min,
            'gratherThanOrEqual',
            minControlName,
            maxControlName
        );
    }

    static minMaxValidator(minControlName = 'min', maxControlName = 'max'): ValidatorFn {
        return CommonValidators.createComparisonValidator(
            (max, min) => max > min,
            'gratherThan',
            minControlName,
            maxControlName
        )
    }
    // Custom min value validator
    static override min(min: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
            if (value !== null && value < min) {
                return { min: VALIDATION_ERROR_MESSAGES['min']({ min }) };
            }
            return null;
        };
    }
    static integer(control: AbstractControl): ValidationErrors | null {
        const value = control.value;
        if (value !== null && !Number.isInteger(Number(value))) {
            return { max: VALIDATION_ERROR_MESSAGES['integer']({}) };
        }
        return null;
    }
    // Custom max value validator
    static override max(max: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
            if (value !== null && value > max) {
                return { max: VALIDATION_ERROR_MESSAGES['max']({ max }) };
            }
            return null;
        };
    }
    static override minLength(minLength: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const invalid = Validators.minLength(minLength);
            const errors = invalid(control);
            if (errors) {
                return { minLength: VALIDATION_ERROR_MESSAGES['minLength']({ minLength }) };
            } else {
                return null;
            }
        };
    }

    static override maxLength(maxLength: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const invalid = Validators.maxLength(maxLength);
            const errors = invalid(control);
            if (errors) {
                return { maxLength: VALIDATION_ERROR_MESSAGES['maxLength']({ maxLength }) };
            } else {
                return null;
            }
        };
    }

    static override email(control: AbstractControl): ValidationErrors | null {
        return CommonValidators.customPattern(REGEXES.email, 'Email address')(control);;
    }
    static password(control: AbstractControl): ValidationErrors | null {
        return CommonValidators.customPattern(REGEXES.password, 'Password')(control);;
    }
    static matchPassword(formGroup: FormGroup) {
        const password = formGroup.get('password');
        const confirmPassword = formGroup.get('confirmPassword');
        if (password != null && confirmPassword != null)
            if (password.value != confirmPassword.value) {
                formGroup.controls['confirmPassword'].setErrors({
                    mismatchPassword: VALIDATION_ERROR_MESSAGES['mismatchPassword']({})
                });
            } else {
                formGroup.controls['confirmPassword'].setErrors(null);
            }
    }

    static cardPrefixNumber(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
            if (!Array.isArray(value)) {
                return { notAnArray: true };
            }

            const isValid = value.every((item: string) => /^\d{6}$/.test(item));
            return isValid ? null : {
                invalidCardPrefixNumber: VALIDATION_ERROR_MESSAGES['invalidCardPrefixNumber']({})
            };
        };
    }

    private static createComparisonValidator(
        comparisonFn: ComparisonFunction,
        errorKey: string,
        minControlName = 'min',
        maxControlName = 'max'
    ): ValidatorFn {
        return (formGroup: AbstractControl): ValidationErrors | null => {
            const minControl = formGroup.get(minControlName);
            const maxControl = formGroup.get(maxControlName);

            if (!minControl || !maxControl) {
                return null;
            }

            const minValue = minControl.value;
            const maxValue = maxControl.value;

            if (minValue !== null && !comparisonFn(maxValue, minValue)) {
                maxControl.setErrors({ [errorKey]: VALIDATION_ERROR_MESSAGES[errorKey]({}) });
                maxControl.markAsDirty();
            } else {
                if (maxControl.hasError(errorKey)) {
                    const errors = { ...maxControl.errors };
                    delete errors[errorKey];
                    maxControl.setErrors(Object.keys(errors).length ? errors : null);
                }
            }

            return null;
        };
    }
}
type ComparisonFunction = (a: number, b: number) => boolean;