import { Directive, HostListener, input } from '@angular/core';
import { DialogComponent } from '../dialog.component';

@Directive({
  selector: '[appDialogClose]',
  standalone: true
})
export class DialogCloseDirective {
  dialogRef = input<DialogComponent | null>(null, { alias: 'appDialogClose' })

  @HostListener('click')
  onClick() {
    this.dialogRef()?.closeModal();
  }
}
