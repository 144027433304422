import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appDialogFooter]',
  standalone: true
})
export class DialogFooterDirective {

  constructor(public templateRef: TemplateRef<object>) { }

}
