import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appDialogContent]',
  standalone: true
})
export class DialogContentDirective {

  constructor(public templateRef: TemplateRef<object>) { }

}
