import { Injectable, signal } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class DataTableFilterService {
  form = signal<FormGroup>(new FormGroup({}));
  collapsed = false;
  collapsible = false;
  collapse() {
    if(this.collapsible)
    this.collapsed = !this.collapsed;
  }
}
