/* eslint-disable @typescript-eslint/no-unused-vars */
import { RegexKey } from "@core/enums";
import { ValidationError, ValidationErrorParams } from "@core/interfaces";

export const REGEXES: Record<RegexKey, RegExp> = {
    [RegexKey.Email]: /^[a-zA-Z0-9._]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    [RegexKey.Password]: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[\d!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/,

};
export const VALIDATION_ERROR_MESSAGES: Record<string, (params: ValidationErrorParams) => ValidationError> = {
    required: (_) => ({ message: 'This field is required.' }),
    minLength: (params) => ({ message: `The input cannot exceed ${params.minLength} characters.` }),
    maxLength: (params) => ({ message: `At least ${params.maxLength} characters are required.` }),
    min: (params) => ({ message: `Value must be at least ${params.min}.` }),
    max: (params) => ({ message: `Value must be at most ${params.max}.` }),
    pattern: (params) => ({ message: `Invalid ${params.label} format.` }),
    mismatchPassword: (_) => ({ message: 'Passwords do not match.' }),
    invalidCardPrefixNumber: (_) => ({ message: 'Invalid entry. The prefix must be a 6-digit number.' }),
    gratherThan: (_) => ({message:'Max value must be greater than the min value.'}),
    gratherThanOrEqual: (_) => ({message:'Max value must be greater than or equal the min value.'}),
    integer: (_) => ({message:'Enter a valid integer number.'}),
};