/* eslint-disable @typescript-eslint/no-empty-function */
import { booleanAttribute, ChangeDetectorRef, Component, contentChild, effect, forwardRef, inject, input, model, OnDestroy, OnInit, signal, TemplateRef, viewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AsyncSelectorOptions, SafeAny } from '@core/types';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOption, MatSelect, MatSelectChange, MatSelectTrigger } from '@angular/material/select';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { HttpService, QueryParamsMapperService } from '@core/services';
import { ApiResponse, PaginatedResponse, PaginationQueryParams } from '@core/models';
import { ScrollEndDirective } from '@shared/directives';
import { PAGINATION_ROWS_COUNTS } from '@core/constants';
import { HttpParams } from '@angular/common/http';
import { LoadStrategy } from '@core/enums';
@Component({
  selector: 'app-async-selector',
  templateUrl: './async-selector.component.html',
  styleUrl: './async-selector.component.scss',
  standalone: true,
  imports: [
    MatFormFieldModule,
    CommonModule,
    MatSelect,
    MatOption,
    NgIf,
    NgFor,
    ScrollEndDirective,
    MatSelectTrigger
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AsyncSelectorComponent),
      multi: true
    }
  ]
})
export class AsyncSelectorComponent implements ControlValueAccessor, OnInit, OnDestroy {
  required = input(false, { transform: booleanAttribute })
  private _queryParamsMapperService = inject(QueryParamsMapperService)
  selectedItem = model();
  /**
   * @description
   * The URL from which to fetch the data for the options.
   */
  dataUrl = input.required<string>();

  options = input<AsyncSelectorOptions>();
  defaultOptions: AsyncSelectorOptions = {
    loadStrategy: LoadStrategy.LAZY_ON_CLICK
  };
  /**
   * @description
   * Specify any supported object by the data source to filter the target data.
   */
  filter = input<Record<string, unknown>>();
  /**
   * @description
   * The label to display for the select input.
   */
  label = input.required<string>();

  /**
   * @description
   * The key in the data object that will be used as the value for the options.
   */
  valueKey = input('id');

  /**
   * @description
   * An array to store the items fetched from the data source.
   */
  items: SafeAny[] = [];

  /**
   * @description
   * Holds the currently selected value in the dropdown.
   */
  value: SafeAny = null;

  /**
   * @description
   * Indicates whether the data is currently being loaded.
   */
  isLoading = false;

  /**
   * @description
   * Indicates whether any content has been loaded successfully.
   */
  hasContent = false;

  /**
   * @description
   * Stores any error message that occurs during data loading.
   */
  errorMessage: string | null = null;

  /**
   * @description
   * The current page number for paginated data loading.
   */
  private _queryParamsSignal = signal<PaginationQueryParams>({
    paging: {
      size: PAGINATION_ROWS_COUNTS[0],
      index: 0
    },
    filter: {},
    orders: { id: 1 }
  });
  /**
   * @description
   * Indicates whether there is more data to be loaded (for pagination).
   */
  hasMoreData = true;

  /**
   * @description
   * Callback function to handle changes in the selected value.
   */
  onChange: SafeAny = () => { };

  /**
   * @description
   * Callback function to handle when the select input is touched.
   */
  onTouched: SafeAny = () => { };

  /**
   * @description
   * Reference to the MatSelect component in the template.
   */
  matSelect = viewChild(MatSelect);

  /**
   * @description
   * Injected HTTP service used to fetch data from the specified URL.
   */
  httpService = inject(HttpService);

  /**
   * @description
   * Template reference for the content that will be displayed inside the options.
   */
  content = contentChild.required(TemplateRef);
  opened = signal<boolean>(false);
  scrolled = signal<boolean>(false);
  selectAllOptionVisible = input(false, {
    transform: booleanAttribute
  });
  cdr = inject(ChangeDetectorRef);
  private _initialValueFetched = signal(false);
  private _firstPageLoaded = signal(false);
  private _subscriptions = new Subscription();
  /**
   *
   */
  constructor() {
    effect(() => {
      if (this.opened()) {
        switch (this.defaultOptions.loadStrategy) {
          case LoadStrategy.EAGER_ON_CLICK:
            this._subscriptions.add(
              this.loadData().subscribe(() => {})
            );
            break;
          case LoadStrategy.LAZY_ON_CLICK:
            if (!this._firstPageLoaded()) {
              this._resetPagination();
              this._subscriptions.add(
                this.loadData().subscribe(() => {
                  this._firstPageLoaded.set(true);
                })
              );
            }
            if (this.scrolled()) {
              this.scrolled.set(false);
              if (!this.isLoading && this.hasMoreData) {
                this._nextPage();
                this.loadData().subscribe();
              }
            }
            break;
        }
      }
    }, { allowSignalWrites: true });
  }
  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }
  ngOnInit(): void {
    this.defaultOptions = { ...this.defaultOptions, ...this.options() };

    switch (this.defaultOptions.loadStrategy) {
      case LoadStrategy.EAGER_ON_INIT:
        this._queryParamsSignal.update((params: PaginationQueryParams) => ({
          ...params,
          filter: { ...params.filter, ...this.filter() },
          paging: {
            index: 0,
            size: 100
          }
        }));
        this._subscriptions.add(
          this.loadData().subscribe(() => {
            if (this.value) {
              const item = this.items.find(item =>
                item[this.valueKey()] === this.value
              );
              this.value = item;
              this.selectedItem.set(item);
              this.onChange(this.value);
            }
          })
        );
        break;
      case LoadStrategy.LAZY_ON_INIT:
        this._queryParamsSignal.update((params: PaginationQueryParams) => ({
          ...params,
          filter: { ...params.filter, ...this.filter() },
        }));
        break;
    }
  }
  onOpenChange(opened: boolean) {
    this.opened.set(opened);
  }

  onScroll() {
    this.scrolled.set(true);
  }

  loadData(): Observable<void> {
    this.isLoading = true;
    return this.fetchData().pipe(
      tap(data => {
        if (this._initialValueFetched() && !this._firstPageLoaded()) {
          // If we've fetched an initial item and this is the first page load
          const newItems = data.filter(item =>
            !this.items.some(existingItem =>
              existingItem[this.valueKey()] === item[this.valueKey()]
            )
          );
          this.items = [...this.items, ...newItems];
        } else {
          this.items = [...this.items, ...data];
        }
        this.isLoading = false;
      }),
      catchError(error => {
        console.error('Error fetching more data:', error);
        this.isLoading = false;
        return of([]);
      }),
      map(() => void 0)
    );
  }

  fetchData(): Observable<SafeAny[]> {
    const url = `${this.dataUrl()}`;
    const params = new HttpParams({ fromObject: { ...this._queryParamsMapperService.mapQueryParams<PaginationQueryParams>(this.queryParams) } })
    return this.httpService.get<PaginatedResponse<SafeAny>>(url, params).pipe(
      tap(data => {
        this.hasMoreData = data.data.base.total > this.items.length;
      }),
      map(result => result.data.items)
    );
  }
  writeValue(value: number): void {
    this.value = value;
  }

  registerOnChange(fn: SafeAny): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: SafeAny): void {
    this.onTouched = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setDisabledState?(_isDisabled: boolean): void {
    // Implement if needed
  }

  selectionChange(event: MatSelectChange) {
    this.onChange(event.value[this.valueKey()]);
    this.selectedItem.set(event.value);
  }
  private _fetchItemById(id: SafeAny): Observable<SafeAny> {
    const url = `${this.dataUrl()}/${id}`;
    return this.httpService.get<ApiResponse<SafeAny>>(url).pipe(
      map(response => response.data),
      catchError(error => {
        console.error('Error fetching item by ID:', error);
        return of(null);
      })
    );
  }
  private _resetPagination() {
    this._queryParamsSignal.update(params => ({
      ...params,
      paging: {
        ...params.paging,
        index: 0
      }
    }));
  }
  private _nextPage() {
    this._queryParamsSignal.update((params: PaginationQueryParams) => ({
      paging: { ...params.paging, index: params.paging.index + 1 },
      filter: { ...params.filter },
      orders: { id: 1 }
    }));
  }
  get queryParams() {
    return this._queryParamsSignal();
  }
}