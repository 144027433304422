import { PaymentStatusEnum } from "@core/enums";
import { VerificationStatus } from "@core/models";

export function getPaymentStatus(kycStatus: PaymentStatusEnum): VerificationStatus {
    const statusMap: Record<PaymentStatusEnum, VerificationStatus> = {
        [PaymentStatusEnum.None]: { text: 'None', color: 'gray', icon: 'fa-solid fa-circle' },
        [PaymentStatusEnum.Pending]: { text: 'Pending', color: 'blue-light', icon: 'fa-solid fa-hourglass-half' },
        [PaymentStatusEnum.Verifying]: { text: 'Verifying', color: 'yellow', icon: 'fa-solid fa-question' },
        [PaymentStatusEnum.Completed]: { text: 'Completed', color: 'green', icon: 'fa-solid fa-check' },
        [PaymentStatusEnum.Canceled]: { text: 'Canceled', color: 'red', icon: 'fa-solid fa-times-circle' },
        [PaymentStatusEnum.Refunded]: { text: 'Refunded', color: 'blue-light', icon: 'fa-solid fa-undo' },
        [PaymentStatusEnum.Failed]: { text: 'Failed', color: 'red-light', icon: 'fa-solid fa-times' },
        [PaymentStatusEnum.Exception]: { text: 'Exception', color: 'red-light', icon: 'fa-solid fa-exclamation-triangle' },
    };

    return statusMap[kycStatus] || { text: 'Unknown', color: 'black', icon: 'fa-question-circle' };
}
