@let optionTemplate = content();
@let showAllOption = selectAllOptionVisible();
<mat-form-field class="w-full">
    <mat-label>{{label()}}</mat-label>
    <mat-select class="app-mat-mdc-select" [(value)]="value" (openedChange)="onOpenChange($event)"
        (selectionChange)="selectionChange($event)" (closed)="onTouched()" [required]="required()">
        <mat-select-trigger>
            <span *ngIf="showAllOption && !value">All</span>
            <ng-container *ngIf="value">
                <ng-container *ngTemplateOutlet="optionTemplate; context: { $implicit: value }"></ng-container>
            </ng-container>
        </mat-select-trigger>
        <div class="app-mat-select-options" appScrollEnd (scrollEnd)="onScroll()">
            @if(showAllOption){
                <mat-option [value]="''">All</mat-option>
            }@else {
                <mat-option></mat-option>
            }
            <mat-option *ngIf="isLoading && items.length === 0" disabled>Loading...</mat-option>
            <mat-option *ngIf="errorMessage" disabled>{{errorMessage}}</mat-option>
            <mat-option *ngFor="let item of items" [value]="item">
                <ng-container *ngTemplateOutlet="optionTemplate; context: { $implicit: item }"></ng-container>
            </mat-option>
            <mat-option *ngIf="isLoading && items.length > 0" disabled>Loading more...</mat-option>
            <mat-option *ngIf="!isLoading && !hasMoreData && items.length > 0" disabled>No more items</mat-option>
        </div>
    </mat-select>
</mat-form-field>