import { Directive, HostListener, input } from '@angular/core';
import { DialogComponent } from '@shared/components';

@Directive({
  selector: '[appDialogOpen]',
  standalone: true
})
export class DialogOpenDirective {
  dialogRef = input<DialogComponent | null>(null, { alias: 'appDialogOpen' })

  @HostListener('click')
  onClick() {
    this.dialogRef()?.openModal();
  }
}
